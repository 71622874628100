import { Middleware } from '@nuxt/types'

const authMiddleware: Middleware = ({ route, redirect }) => {
  // Jika rute memerlukan otentikasi dan pengguna tidak terotentikasi, redirect ke halaman login
//   if (route.meta.requiresAuth && !isLoggedIn()) {
// }
return redirect('/')
}

export default authMiddleware

function isLoggedIn() {
  const accessToken = localStorage.getItem('accessToken')
  return !!accessToken
}
